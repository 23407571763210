<template>
  <div class="container-fluid">
    <!-- begin::page header -->
    <div class="page-header">
      <h3>Dashboard</h3>
    </div>
    <!-- end::page header -->

    <div class="row">
      <div class="col-md-4">
        <router-link :to="{ name: 'bidbonds.index' }">
          <div class="card stat-card">
            <div class="card-body red">
              <div class="d-flex justify-content-between align-items-center">
                <div>
                  <h6 class="text-uppercase font-size-11 m-b-15">
                    My Bidbonds
                  </h6>
                  <h4 class="m-b-0">
                    {{ stats.bidbonds }}
                  </h4>
                </div>
                <div>
                  <div class="icon">
                    <i class="lni-layers"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </router-link>
      </div>
      <div class="col-md-4">
        <router-link :to="{ name: 'performancebonds.index' }">
          <div class="card stat-card">
            <div class="card-body blue">
              <div class="d-flex justify-content-between align-items-center">
                <div>
                  <h6 class="text-uppercase font-size-11 m-b-15">
                    My Performancebonds
                  </h6>
                  <h4 class="m-b-0">
                    {{ stats.performancebonds }}
                  </h4>
                </div>
                <div>
                  <div class="icon">
                    <i class="lni-certificate"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </router-link>
      </div>
      <div class="col-md-4">
        <router-link
          :to="{ name: 'companies.index' }"
          class="text-secondary"
        >
          <div class="card stat-card">
            <div class="card-body green">
              <div class="d-flex justify-content-between align-items-center">
                <div>
                  <h6 class="text-uppercase font-size-11 m-b-15">
                    My Companies
                  </h6>
                  <h4 class="m-b-0">
                    {{ stats.companies }}
                  </h4>
                </div>
                <div>
                  <div class="icon">
                    <i class="lni-folder"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </router-link>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">Latest Bidbonds <router-link :to="{ name: 'bidbonds.index' }" class="btn btn-primary btn-sm float-right">View All</router-link></h5>
            <table class="table table-striped mt-4">
              <thead>
                <tr>
                  <td>#</td>
                  <td>Company</td>
                  <td>Procuring Entity</td>
                  <td>Amount</td>
                  <td>Status</td>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(bidbond, i) in bidbonds" :key="`bidbond-${i}`">
                  <td><router-link :to="{ name: 'bidbonds.show', params: { id: bidbond.bidbond_secret }}">{{ bidbond.bidbond_secret }}</router-link></td>
                  <td>{{ bidbond.company_name }}</td>
                  <td>{{ bidbond.counterparty_name }}</td>
                  <td>{{ bidbond.tender_amount }}</td>
                  <td>{{ bidbond.bidbond_status }}</td>
                </tr>
                <tr v-if="bidbonds.length == 0">
                  <td colspan="5">
                    <div class="alert alert-info">There is no data to display</div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">Latest Performancebonds <router-link :to="{ name: 'performancebonds.index' }" class="btn btn-primary btn-sm float-right">View All</router-link></h5>
            <table class="table table-striped mt-4">
              <thead>
                <tr>
                  <td>#</td>
                  <td>Company</td>
                  <td>Procuring Entity</td>
                  <td>Amount</td>
                  <td>Status</td>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(performancebond, i) in performancebonds" :key="`performancebond-${i}`">
                  <td><router-link :to="{ name: 'performancebonds.show', params: { id: performancebond.performancebond_secret }}">{{ performancebond.performancebond_secret }}</router-link></td>
                  <td>{{ performancebond.company_name }}</td>
                  <td>{{ performancebond.counterparty_name }}</td>
                  <td>{{ performancebond.tender_amount }}</td>
                  <td>{{ performancebond.performancebond_status }}</td>
                </tr>
                <tr v-if="performancebonds.length == 0">
                  <td colspan="5">
                    <div class="alert alert-info">There is no data to display</div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    this.fetch()
  },

  data() {
    return {
      stats: {
        companies: 0,
        performancebonds: 0,
        bidbonds: 0
      },
      bidbonds: [],
      performancebonds: []
    }
  },

  methods: {
    fetch() {
      this.$loader.start();

    this.$axios
      .get("/api/v1/dashboard/home")
      .then(response => {
        this.stats = response.data.stats
        this.bidbonds = response.data.bidbonds
        this.performancebonds = response.data.performancebonds
        this.$loader.stop()
      })
    }
  }
}
</script>