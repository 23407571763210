<template>
  <div class="side-menu">
    <div class="container">
      <div class="side-menu-body">
        <div class="menu-header">
          <div class="logo">
            <img  :src="logoUrl"  alt="logo">
          </div>
          <div class="menu">
            <p>Welcome,</p>
            <p><router-link :to="{ name: 'profile' }">{{ $store.getters.getAuthUser.name }}</router-link></p>
          </div>
        </div>
        <ul>
          <li class="side-menu-divider m-t-0"></li>
          <li>
            <router-link :to="{ name: 'dashboard' }">
              <i class="icon lni-dashboard"></i>
              <span>Dashboard</span>
            </router-link>
          </li>
          <li v-if="$canAny('bidbonds')">
            <router-link :to="{ name: 'bidbonds.index' }">
              <i class="icon lni-folder"></i>
              <span>Bid Bonds</span>
            </router-link>
          </li>
          <li v-if="$canAny('performancebonds')">
            <router-link :to="{ name: 'performancebonds.index' }">
              <i class="icon lni-tab"></i>
              <span>Performance Bonds</span>
            </router-link>
          </li>
          <li v-if="$canAny('companies')">
            <router-link :to="{ name: 'companies.index' }">
              <i class="icon lni-layers"></i>
              <span>Companies</span>
            </router-link>
          </li>
          <li >
            <router-link :to="{ name: 'banks.index' }">
              <i class="icon lni-home"></i>
              <span>Banks</span>
            </router-link>
          </li>
          <li v-if="$canAny('counterparties')">
            <router-link :to="{ name: 'counterparties.index' }">
              <i class="icon lni-files"></i>
              <span>Counterparties</span>
            </router-link>
          </li>
          <li v-if="$canAny('bidbond_pricing')">
            <router-link :to="{ name: 'bidbond-pricing.index' }">
              <i class="icon lni-money-protection"></i>
              <span>Bidbond Pricing</span>
            </router-link>
          </li>
          <li v-if="$canAny('performancebond_pricing')">
            <router-link :to="{ name: 'performancebond-pricing.index' }">
              <i class="icon lni-shield"></i>
              <span>Performancebond Pricing</span>
            </router-link>
          </li>
          <li v-if="$canAny('bond_templates')">
            <router-link :to="{ name: 'bond-templates.index' }">
              <i class="icon lni-certificate"></i>
              <span>Templates</span>
            </router-link>
          </li>
          <li v-if="$canAny('roles')">
            <router-link :to="{ name: 'roles.index' }">
              <i class="icon lni-brush"></i>
              <span>Roles</span>
            </router-link>
          </li>
          <li v-if="$canAny('users')">
            <router-link :to="{ name: 'users.index' }">
              <i class="icon lni-users"></i>
              <span>Users</span>
            </router-link>
          </li>
          <li v-if="$canAny('quotes')">
            <router-link :to="{ name: 'quotes.index' }">
              <i class="icon lni-pencil"></i>
              <span>Quotes</span>
            </router-link>
          </li>
          <li v-if="$canAny('settings')">
            <router-link :to="{ name: 'settings' }">
              <i class="icon lni-cog"></i>
              <span>Settings</span>
            </router-link>
          </li>
        </ul>
        <ul class="bottom-menu">
          <li class="side-menu-divider m-t-0"></li>
          <li>
            <a href="#" @click.prevent="logout">
              <i class="icon lni-ban"></i>
              <span>Logout</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    this.$can('users.index')
  },

  methods: {
    logout() {
      this.$axios.post('/api/v1/logout').then(() => {
        this.$auth.removeToken()
        this.$auth.removePermissions()
        window.location.href = this.$router.resolve({ name: 'login' }).href
      })
    }
  },
  data() {
    return {
      logoUrl
      : require("@/../public/mtender_logo.jpg")
    }
  }
};
</script>
