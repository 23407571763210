<template>
  <div class="guest bg-1">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-6">
          <div class="row">
            <div class="col-md-9 pl-5">
              <div class="logo mt-3">
                <img src="/mtender_logo.jpg" alt="logo" />
              </div>
              <h4 class="mt-3 font-weight-bolder">Welcome {{ user.name }}</h4>
            </div>
          </div>
          <div class="row justify-content-center mt-4 quote">
            <div class="col-md-9">
              <div class="tab-content shadow mb-4">
                <h5>Complete your profile to continue</h5>
                <hr />
                <form @submit.prevent="saveId" v-if="user.id_number == null">
                  <div class="form-group">
                    <label>ID Number</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="ID Number"
                      name="id_number"
                      v-model="id_number"
                    />
                  </div>
                  <div class="form-group text-right">
                    <button class="btn btn-secondary">Save</button>
                  </div>
                </form>

                <form @submit.prevent="savePhone" v-if="user.id_number != null">
                  <div class="alert alert-danger" v-if="invalid_code">
                    The code entered is invalid
                  </div>
                  <div class="alert alert-danger" v-if="invalid_phone_number">
                    The phone number is invalid
                  </div>
                  <div class="input-group mb-3">
                    <vue-tel-input v-model="phone_number" placeholder="Phone Number" defaultCountry="ke" inputClasses="form-control" :inputOptions="{ showDialCode: true }" />
                    <div class="input-group-append">
                      <button
                        class="btn btn-primary"
                        type="submit"
                        v-if="request_otp"
                      >
                        Request OTP
                      </button>
                      <a
                        href="#"
                        @click.prevent=""
                        class="btn btn-info disabled"
                        type="submit"
                        v-if="!request_otp"
                        >OTP Sent</a
                      >
                    </div>
                  </div>
                </form>
                <form @submit.prevent="validate" v-if="otp_sent">
                  <div class="form-group">
                    <label>Enter the password sent to your registered Email Address.</label>
                    <input
                      type="text"
                      placeholder="1234"
                      class="form-control"
                      v-model="otp"
                      required
                    />
                  </div>
                  <div class="form-group">
                    <button class="btn btn-secondary btn-block">Proceed</button>
                  </div>
                </form>
                <p class="mt-5">
                  <a href="#" @click.prevent="logout">LOGOUT</a>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="home-background w-100 h-100"></div>
        </div>
      </div>
    </div>

    <guest-footer />
  </div>
</template>

<script>
import GuestFooter from "@/components/GuestFooter";

export default {
  components: {
    GuestFooter,
  },

  mounted() {
    document.body.classList.add("pt-0");
  },

  destoyed() {
    document.body.classList.remove("pt-0");
  },

  data() {
    return {
      id_number: "",
      phone_number: "",
      otp: "",
      otp_sent: false,
      request_otp: true,
      code_sent: false,
      invalid_code: false,
      invalid_phone_number: false
    };
  },

  methods: {
    logout() {
      this.$loader.start();
      this.$axios.post("/api/v1/logout").then(() => {
        this.$auth.removeToken();
        window.location.href = this.$router.resolve({ name: "login" }).href;
      });
    },

    saveId() {
      let data = {
        id_number: this.id_number,
        action: "id_number",
      };

      this.$loader.start();
      this.$axios.post("/api/v1/dashboard/profile", data).then((response) => {

        if(!response.data.error ){
          this.$toast.success("ID verified successfully!")

        this.$store.dispatch("setAuthUser", response.data);
        this.$loader.stop();
        this.$router.push({ name: "dashboard" });
        }else if(response.data.error){

          this.$loader.stop();
          this.$toast.error(response.data.error)

        }
   
      });
    },

    savePhone() {
      let data = {
        phone_number: this.phone_number,
        action: "phone_number",
      };

      this.$loader.start();
      this.invalid_code = false;
      this.invalid_phone_number = false;
      this.$axios.post("/api/v1/dashboard/profile", data).then((response) => {
        this.otp_sent = true;
        this.request_otp = false;
        this.code_sent = response.data.code;
        this.$loader.stop();

        setTimeout(() => {
          this.request_otp = true
        }, 500000);
      }).catch(() => {
        this.invalid_phone_number = true
        this.$loader.stop()
      });
    },

    validate() {
      let data = {
        otp: this.otp,
        action: "otp",
      };

      this.$loader.start();
      this.$axios
        .post("/api/v1/dashboard/profile", data)
        .then(() => {
          window.location.reload(true);
        })
        .catch(() => {
          this.$loader.stop();
          this.invalid_code = true;
        });
    },
  },

  computed: {
    user() {
      return this.$store.getters.getAuthUser;
    },
  },
};
</script>
