<template>
  <div class="container">
    <!-- begin::page header -->
    <div class="page-header">
      <h3>Dashboard</h3>
    </div>
    <!-- end::page header -->

    <div class="row">
      <div class="col-md-3">
        <router-link :to="{ name: 'bidbonds.index' }">
          <div class="card stat-card">
            <div class="card-body red">
              <div class="d-flex justify-content-between align-items-center">
                <div>
                  <h6 class="text-uppercase font-size-11 m-b-15">
                    Bidbonds
                  </h6>
                  <h4 class="m-b-0">
                    {{ stats.bidbonds }}
                  </h4>
                </div>
                <div>
                  <div class="icon">
                    <i class="lni-home"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </router-link>
      </div>
      <div class="col-md-3">
        <router-link :to="{ name: 'performancebonds.index' }">
          <div class="card stat-card">
            <div class="card-body blue">
              <div class="d-flex justify-content-between align-items-center">
                <div>
                  <h6 class="text-uppercase font-size-11 m-b-15">
                    Performancebonds
                  </h6>
                  <h4 class="m-b-0">
                    {{ stats.performancebonds }}
                  </h4>
                </div>
                <div>
                  <div class="icon">
                    <i class="lni-home"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </router-link>
      </div>
      <div class="col-md-3">
        <router-link :to="{ name: 'quotes.index' }">
          <div class="card stat-card">
            <div class="card-body yellow">
              <div class="d-flex justify-content-between align-items-center">
                <div>
                  <h6 class="text-uppercase font-size-11 m-b-15">
                    Quotes
                  </h6>
                  <h4 class="m-b-0">
                    {{ stats.quotes }}
                  </h4>
                </div>
                <div>
                  <div class="icon">
                    <i class="lni-home"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </router-link>
      </div>
      <div class="col-md-3">
        <router-link
          :to="{ name: 'companies.index' }"
          class="text-secondary"
        >
          <div class="card stat-card">
            <div class="card-body green">
              <div class="d-flex justify-content-between align-items-center">
                <div>
                  <h6 class="text-uppercase font-size-11 m-b-15">
                    Companies
                  </h6>
                  <h4 class="m-b-0">
                    {{ stats.companies }}
                  </h4>
                </div>
                <div>
                  <div class="icon">
                    <i class="lni-home"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </router-link>
      </div>
    </div>

    <div class="row" v-if="! is_loading">
      <div class="col-md-6">
        <div class="card">
          <div class="card-header">Quote Generation <small class="float-right">Last 30 days</small></div>
          <div class="card-body">
            <chart :data="quotes_chart" :options="test_options" type="bar" />
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="card">
          <div class="card-header">Bond Generation <small class="float-right">Last 7 days</small></div>
          <div class="card-body">
            <chart :data="bonds_chart" :options="test_options" type="bar" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */

export default {
  name: "dashboard.home",

  mounted() {
    this.is_loading = true;
    this.$loader.start();

    this.$axios
      .get("/api/v1/dashboard/home")
      .then((response) => {
        this.stats = response.data.stats;

        let quotes_data = response.data.quotes_chart
        quotes_data.datasets = quotes_data.datasets.map(data_set => {
          data_set['backgroundColor'] = '#ff3000'

          return data_set
        })
        this.quotes_chart = quotes_data

        let bonds_data = response.data.bonds_chart
        let colors = ['#009245', '#ea4335']
        bonds_data.datasets = bonds_data.datasets.map((data_set, i) => {
          data_set['backgroundColor'] = colors[i]

          return data_set
        })
        this.bonds_chart = bonds_data

        this.is_loading = false;
        this.$loader.stop();
      })
      .catch((error) => {
        this.$http(error.response);
        this.$loader.stop();
      });
  },

  data() {
    return {
      stats: {
        bidbonds: "",
        quotes: "",
        companies: "",
        counter_parties: "",
      },
      is_loading: true,
      quotes_chart: {},
      bonds_chart: {},
      test_options: {
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
            },
          ],
        },
      },
    };
  },
};
</script>
