<template>
  <div class="dashboard">
    <template v-if="completed_profile">
      <side-menu />

      <main class="main-content" id="main-content">
        <router-view />
      </main>
    </template>
    <template v-if="! completed_profile">
      <complete-profile />
    </template>
  </div>
</template>

<script>
import "@/assets/scss/dashboard.scss";

import SideMenu from "@/components/dashboard/SideMenu";
import CompleteProfile from "@/components/dashboard/CompleteProfile";

export default {
  components: {
    SideMenu,
    CompleteProfile
  },

  computed: {
    completed_profile() {
      let status = true
      let user = this.$store.getters.getAuthUser

      if(user.id_number == null) {
        status = false
      }

      if(! user.otp_verified) {
        status = false
      }

      return status
    }
  }
};
</script>
