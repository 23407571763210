<template>
  <div>
    <template v-if="is_admin">
      <admin />
    </template>
    <template v-else>
      <customer />
    </template>
  </div>
</template>

<script>
import Admin from './Admin'
import Customer from './Customer'

export default {
  components: {
    Admin, Customer
  },

  computed: {
    is_admin() {
      return this.$store.getters.getAuthUser.is_admin
    }
  }
}
</script>